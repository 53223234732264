import {
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import { useState } from "react";




export default function SearchComponent() {
    const [searchedPostcode, setSearchedPostcode] = useState<boolean>(false);
    const [postcode, setPostcode] = useState<string>("");
    const [open, setOpen] = useState<boolean>(false);
    const [searchAddress, setSearchAddress] = useState<string>("");
    const [selectText, setSelectText] = useState<string>("");
    const [open_modal, setOpen_modal] = useState<boolean>(false);
    const [searchDisabled, setSearchDisabled] = useState<boolean>(false);
    const [data, setData] = useState<string[]>([]);

    const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        postcodeSearch();
    };
  

    function postcodeSearch() {
        const url =
            "https://sohapi.solarwatcher.co.uk/api/Soh/postcodeAddress?postcode=" +
            postcode;

        fetch(url, {
            method: "get",
        })
            .then((res) => {
                return res.json();
            })
            .then((res: string[]) => {
                if (res.length > 0) {
                    setData(res);
                    setSelectText("Select address...");
                    setSearchedPostcode(true);
                } else {
                    setSearchedPostcode(true);
                    setData([]);
                    setSelectText("No results, check the postcode...");
                }
            })
            .catch((e) => console.log(e));
    }
    return (
        <div>
            <form onSubmit={onFormSubmit}>
                <Grid container direction="row" spacing={2}>
                    <Grid item xs={12} sm={12} md={8}>
                        <TextField
                            aria-label="Search"
                            label="Search Postcode"
                            InputLabelProps={{ style: { color: "#3C3C3C" } }}
                            placeholder="Postcode"
                            variant="filled"
                            onChange={(e) => setPostcode(e.target.value)}
                            fullWidth
                            inputProps={{ "aria-label": "search" }}
                            value={postcode === "" ? "" : postcode}
                            style={{ backgroundColor: "white" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <Button
                            // className="cta"
                            onClick={postcodeSearch}
                            //color="primary"
                            aria-label="search"
                            // component="span"
                            fullWidth
                            disabled={postcode.length < 2}
                            style={{
                                height: "56px",
                                backgroundColor: "white",
                                fontFamily: "gilroyregular",
                            }}
                        >
                            Find Address
                        </Button>
                    </Grid>

                    {/* if statement: if no postcode, then address dropdown not show */}
                    {searchedPostcode ? (
                        <Grid item xs={12}>
                            <FormControl
                                variant="filled"
                                style={{
                                    marginTop: "10px",
                                    position: "relative",
                                    width: "100%",
                                }}
                            >
                                <InputLabel
                                    id="select-label"
                                    style={{ color: "#3c3c3c" }}
                                >
                                    {selectText}
                                </InputLabel>
                                <Select
                                    labelId="select-label"
                                    inputProps={{ "aria-label": "select" }}
                                    label="select"
                                    value={searchAddress}
                                    disabled={data.length < 1 ? true : false}
                                    onChange={(e) =>
                                        setSearchAddress(String(e.target.value))
                                    }
                                    style={{
                                        backgroundColor: "#E8E8E8",
                                        borderRadius: "0px",
                                    }}
                                    MenuProps={{ style: { maxHeight: "60%" } }}
                                >
                                    {data.length < 1 ? (
                                        searchAddress.length == 0 ? (
                                            <MenuItem
                                                value={
                                                    "Please enter an address"
                                                }
                                            >
                                                Please enter an address
                                            </MenuItem>
                                        ) : (
                                            <MenuItem value={searchAddress}>
                                                {searchAddress}
                                            </MenuItem>
                                        )
                                    ) : (
                                        data.map((option, index) => (
                                            <MenuItem
                                                key={index}
                                                value={option}
                                                style={{ maxHeight: "80%" }}
                                            >
                                                {option}
                                            </MenuItem>
                                        ))
                                    )}
                                </Select>
                                <a
                                                                   
                                href={searchAddress.length === 0 ? undefined : "https://switchedonportsmouth.co.uk/switchedonhomes?address="+searchAddress}                                
                                ><Button  className="cta"
                                style={{
                                    marginLeft: "0px",
                                    marginTop: "30px",
                                    height: "56px",
                                    backgroundColor: "white",
                                    fontSize: "20px",
                                    fontFamily: "gilroyregular",
                                }}
                                fullWidth
                                // color="primary"
                                aria-label="search"
                                component="span"
                                disabled={
                                    searchAddress.length === 0
                                        ? true
                                        : false
                                }>open</Button></a>
                                
                            </FormControl>
                        </Grid>
                    ) : (
                        <div></div>
                    )}
                </Grid>
            </form>
        </div>
    );
}
