import React from "react";
import "./App.css";
import "./styles/global.scss";
import SearchComponent from "./components/SearchComponent";
import { Grid } from "@mui/material";

function App() {
    return (
        <main>

            <div
                style={{
                    maxWidth: "860px",
                    margin: "10px auto 10px auto",
                    padding: "20px 40px 30px 40px",
                    borderRadius: "0px",
                    backgroundColor: "darkblue", //textAlign:"center" ,
                    boxShadow:
                        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                }}
            >
                <h5
                    style={{
                        textAlign: "center",
                        color: "white",
                        fontWeight: "normal",
                    }}
                >
                    Find out how to make your home more energy efficient
                </h5>
                <div style={{ textAlign: "left" }}>
                    <span style={{ textAlign: "center", color: "white" }}>
                    Enter your postcode to see the savings you can make and access FREE measures. For PO1 - PO6 postcodes only.
                    </span>
                </div>
                <Grid item alignItems="center" style={{ marginTop: "20px" }}>
                    <SearchComponent />
                </Grid>
            </div>
        
        </main>
    );
}

export default App;
